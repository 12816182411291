<template>
	<div>
		<Header :childIndex="0" :val='1'></Header>
		<div class="banner">
			<div class="bannermain_box">
				<div class="banner_contentlist">
					<div class="banner_contentlist1">
						<h6>学界动态</h6>
					</div>
					<div class="banner_contentlist2">
						<ul>
							<li v-for="(value,index) in AcademicDynamicList" :key="index">
								<router-link :to="{path:'/NewsDetail',query:{id:value.ID,type:6}}">
									<i></i>
									<h3>{{value.Title}}</h3>
								</router-link>
							</li>
						</ul>
					</div>
				</div>
			</div>

			<div class="banner_img">
				<ul>
					<li v-for="(value,index) in bannerList" :key="index">
						<img :src="$ImgUrl + value.PicPath" :onerror="defaultImage" />
					</li>
				</ul>
			</div>
		</div>
		<!--新闻资讯-->
		<div class="yjcgts_main main_news">
			<div class="main_boxc">
				<div class="sm_mainbox">
					<div class="content_title main_title">
						<i></i>
						<img src="../../images/xwzx2_ico.png">
					</div>
					<div class="sm_contentbox zy_contentbox" v-if="newsList.length > 0">
						<div class="zy_contentbox_top" v-if="newsList.length > 0">
							<el-carousel indicator-position="outside" :interval=6000>
								<el-carousel-item v-for="(item,index) in newsList" :key="index">
									<router-link :to="{path:'/NewsDetail',query:{id:item.ID,type:6}}">
										<img :src="$ImgUrl+item.CoverPath" :onerror="$parent.defaultImg2" />
										<h3>{{item.Title}}</h3>
									</router-link>
									<router-link :to="{path:'/NewsDetail',query:{id:item.ID,type:6}}">
										<img :src="$ImgUrl+item.CoverPath" :onerror="$parent.defaultImg2" />
										<h3>{{item.Title}}</h3>
									</router-link>
								</el-carousel-item>
							</el-carousel>
						</div>
						<div class="zy_contentbox_bottom">
							<li v-for="(value,index) in newsList" :key="index">
								<router-link :to="{path:'/NewsDetail',query:{id:value.ID,type:6}}">
									<div class="main_newsc1">
										<span>{{value.ReleaseDate | GetYMDHMS('yyyy')}}</span>
										<font>{{value.ReleaseDate | GetYMDHMS('MM-dd')}}</font>
									</div>
									<div class="main_newsc2">
										<h3>{{value.Title}}</h3>
										<p>{{value.NewContext}}</p>
									</div>
								</router-link>
							</li>
						</div>
					</div>
				</div>
				<!-- 每周一书 -->
				<div class="sm_mainbox main_bookbox">
					<div class="content_title main_title">
						<i></i>
						<img src="../../images/mzys_ico.png">
					</div>
					<div class="sm_contentbox" v-if="BookOfWeek&&BookOfWeek.IsDel!= 1">
						<div class="zy_contentbox_top">
							<a href="javascript:void(0)" @click="toBookDetail(BookOfWeek.id)">
								<div class="zy_contentbox_top1l">
									<img :src="$ImgUrl+BookOfWeek.cover_path" :onerror="$parent.defaultImg1" />
								</div>
							</a>
						</div>
						<div class="zy_contentbox_bottom">
							<a href="javascript:void(0)" @click="toBookDetail(BookOfWeek.id)">
								<h3>{{BookOfWeek.res_name}}</h3>
							</a>
							<div class="zy_contentbox_top1r">
								<span>作者：{{BookOfWeek.res_author}}</span>
								<span>出版社：{{BookOfWeek.publisher}}</span>
								<span v-if="BookOfWeek.Price">定价：{{BookOfWeek.Price}}</span>
								<span v-if="BookOfWeek.publish_date">出版日期：{{BookOfWeek.publish_date | GetYMDHMS('yyyy-MM')}}</span>
							</div>
							<p v-html="BookOfWeek.Itroduce"></p>
							<!-- <p>{{BookOfWeek.Itroduce | GetSubstring(60)}}</p> -->
						</div>
					</div>
				</div>
				<!-- 当月期刊 -->
				<div class="sm_mainbox main_qkbox">
					<div class="content_title main_title">
						<i></i>
						<img src="../../images/dyqk_ico.png">
					</div>
					<div class="sm_contentbox main_qkboxc" v-if="JournalMonthInfo">
						<div class="zy_contentbox_top">
							<a href="javascript:void(0)" @click="toGeneralJournaDetail(JournalMonthInfo.ID)">
								<h3>{{JournalMonthInfo.JournalName }}</h3>
							</a>
							<div class="zy_contentbox_top1">
								<a href="javascript:void(0)" @click="toGeneralJournaDetail(JournalMonthInfo.ID)">
									<div class="zy_contentbox_top1l">
										<img :src="$ImgUrl+JournalMonthInfo.CoverPath" :onerror="$parent.defaultImg1" />
									</div>
								</a>
								<div class="zy_contentbox_top1r">
									<span>ISSN：{{JournalMonthInfo.ISSN}}</span>
									<span>CN：{{JournalMonthInfo.CN}}</span>
									<span>主办：{{JournalMonthInfo.HostUnit}}</span>
									<span>出版周期：{{JournalMonthInfo.CycleText}}</span>
								</div>
							</div>
						</div>
						<div class="zy_contentbox_bottom">
							<p>{{JournalMonthInfo.Summary | GetSubstring(55)}}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 专家学者库、研究机构库、出版机构库 -->
		<div class="yjcgts_main main_export">
			<div class="main_boxc">
				<div class="sm_mainbox main_exportbox">
					<div class="content_title main_title">
						<i></i>
						<img src="../../images/zjxzk_indexico.png">
						<a href="javascript:void(0)" @click="toMechanismList(2)">更多</a>
					</div>
					<div class="sm_contentbox main_exportboxc" v-if="exportList.length > 0">
						<div class="zy_contentbox_top">
							<div class="mzys_contentbox_top1" v-if="exportCon">
								<div class="mzys_contentbox_top1l">
									<a href="javascript:void(0)" @click="toMechanismDetail(exportCon.ID,2)">
										<img :src="$ImgUrl + exportCon.Conver" :onerror="$parent.defaultImg1" />
										<i></i>
									</a>
								</div>
								<div class="mzys_contentbox_top1r">
									<a href="javascript:void(0)" @click="toMechanismDetail(exportCon.ID,2)">
										<font>{{exportCon.Name}}</font>
									</a>
									<p>{{exportCon.IntroduceText | GetSubstring(120)}}</p>
								</div>
							</div>
							<div class="mzys_contentbox_top2 export_contentbox_top2">
								<li v-for="(value,index) in ExpertRelationEntryList" :key="index">
									<i></i>
									<a href="javascript:void(0)" @click="toEntryDetail(value.ID)">
										<h3>{{value.Name}}</h3>
									</a>
								</li>
							</div>
						</div>
						<div class="yz_contentbox2 export_contentbox2">
							<li v-for="(value,index) in exportList" :key="index" @click="changeExport(index)">
								<span>{{value.Name}}</span>
							</li>
						</div>
					</div>
				</div>
				<div class="main_yjbox">
					<div class="sm_mainbox main_yjboxc">
						<div class="content_title main_title">
							<i></i>
							<img src="../../images/yjjgk_indexico.png">
							<a href="javascript:void(0)" @click="toMechanismList(1)">更多</a>
						</div>
						<div class="sm_contentbox main_yjboxc1" v-if="searchMechanismList.length > 0">
							<div class="zy_contentbox_top">
								<div class="mzys_contentbox_top1">
									<div class="mzys_contentbox_top1l">
										<a href="javascript:void(0)" @click="toMechanismDetail(searchMechanismList[0].ID,1)">
											<img :src="$ImgUrl + searchMechanismList[0].Conver" :onerror="$parent.defaultImg1" />
										</a>
									</div>
									<div class="main_yjboxc1r">
										<a href="javascript:void(0)" @click="toMechanismDetail(searchMechanismList[0].ID,1)">
											<h3>{{searchMechanismList[0].Name}}</h3>
										</a>
										<p>{{searchMechanismList[0].IntroduceText | GetSubstring(40)}}</p>
										<div class="main_yjboxc1rc">
											<li v-for="(value,index) in searchMechanismList.slice(1)" :key="index">
												<a href="javascript:void(0)" @click="toMechanismDetail(value.ID,1)">
													<span>{{value.Name}}</span>
												</a>
											</li>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="sm_mainbox main_yjboxc">
						<div class="content_title main_title">
							<i></i>
							<img src="../../images/cbjgk_ico.png">
							<a href="javascript:void(0)" @click="toMechanismList(0)">更多</a>
						</div>
						<div class="sm_contentbox main_yjboxc1" v-if="publishMechanismList.length > 0">
							<div class="zy_contentbox_top">
								<div class="mzys_contentbox_top1">
									<div class="mzys_contentbox_top1l">
										<a href="javascript:void(0)" @click="toMechanismDetail(publishMechanismList[0].ID,0)">
											<img :src="$ImgUrl + publishMechanismList[0].Conver" :onerror="$parent.defaultImg1" />
										</a>
									</div>
									<div class="main_yjboxc1r">
										<a href="javascript:void(0)" @click="toMechanismDetail(publishMechanismList[0].ID,0)">
											<h3>{{publishMechanismList[0].Name}}</h3>
										</a>
										<p>{{publishMechanismList[0].IntroduceText | GetSubstring(40)}}</p>
										<div class="main_yjboxc1rc">
											<li v-for="(value,index) in publishMechanismList.slice(1)" :key="index">
												<a href="javascript:void(0)" @click="toMechanismDetail(value.ID,0)">
													<span>{{value.Name}}</span>
												</a>
											</li>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="main_media">
					<div class="main_video">
						<div class="content_title main_title">
							<i></i>
							<img src="../../images/zxsp_ico.png">
							<a href="javascript:void(0)" @click="toMediaList(5)">更多</a>
						</div>
						<div class="main_videoc">
							<div class="main_videoc_l" v-if="VideoList.length > 0">
								<img :src="$ImgUrl + VideoList[0].CoverPath" :onerror="$parent.defaultImg1" />
								<i class="video_playbtn" @click="playMedia(VideoList[0].ID,5)"></i>
							</div>
							<div class="main_videoc_r">
								<ul>
									<li v-for="(value,index) in VideoList.slice(1)" :key="index">
										<div class="main_videoc_rc">
											<img :src="$ImgUrl + value.CoverPath" :onerror="$parent.defaultImg1" />
											<i class="video_playbtn" @click="playMedia(value.ID,5)"></i>
										</div>
										<h3 @click="playMedia(value.ID,5)" :title="value.ResName">{{value.ResName}}</h3>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="main_audio">
						<div class="content_title main_title">
							<i></i>
							<img src="../../images/zxyp_ico.png">
							<a href="javascript:void(0)" @click="toMediaList(4)">更多</a>
						</div>
						<div class="main_audioc">
							<ul>
								<li v-for="(value,index) in AudioList" :key="index" @click="playMedia(value.ID,4)">
									<h3 :title="value.ResName">—— {{value.ResName}}</h3>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--索引目录-->
		<div class="syml_main main_symlbox">
			<div class="syml_mainbox">
				<div class="syml_mainbox_title">
					<div class="syml_title">
						<img src="../../images/symlsjk_ico.png" />
					</div>
				</div>
				<div class="main_boxc">
					<div class="content_title main_title">
						<i></i>
						<img src="../../images/sk_ico.png" />
						<a href="javascript:void(0)" @click="toMoreList('石窟',$CatalogGrottoId,0)">更多</a>
					</div>
					<div class="main_symlboxl" v-if="CatalogGrottoList.length > 0">
						<div class="main_symlboxl_l" @click="toElementDetail(CatalogGrottoList[0].id)">
							<div class="main_symlboxl_lc1">
								<img :src="$ImgUrl+CatalogGrottoList[0].cover_path" :onerror="$parent.defaultImg1" />
							</div>
							<div class="main_symlboxl_lc"></div>
						</div>
						<div class="main_symlboxl_r">
							<a href="javascript:void(0)" @click="toElementDetail(CatalogGrottoList[0].id)">
								<h3 :title="CatalogGrottoList[0].element_name">{{CatalogGrottoList[0].element_name}}</h3>
							</a>
							<!-- <span>{{CatalogGrottoList[0].element_name}}</span> -->
							<p>{{CatalogGrottoList[0].Annotation | GetSubstring(104)}}</p>
						</div>
					</div>
					<div class="syml_list main_symlboxr">
						<ul>
							<li v-for="(value,index) in CatalogGrottoList.slice(1)" :key="index">
								<a href="javascript:void(0)" @click="toElementDetail(value.id)">
									<img :src="$ImgUrl+value.cover_path" :onerror="$parent.defaultImg1" />
									<h3 :title="value.element_name">{{value.element_name}}</h3>
								</a>
							</li>
						</ul>
					</div>
				</div>
				<!--文献文物-->
				<div class="wxww_main">
					<div class="main_boxc">
						<div class="wxww_boxf">
							<div class="content_title main_title">
								<i></i>
								<img src="../../images/wx_ico.png" />
								<a href="javascript:void(0)" @click="toMoreList('文献',$CatalogLiteratureId,0)">更多</a>
							</div>
							<div class="wxww_boxf_list">
								<ul>
									<li v-for="(value,index) in CatalogLiteratureList" :key="index">
										<a href="javascript:void(0)" @click="toElementDetail(value.id)">
											<em></em>
											<font v-if="value.Code">{{value.Code}}</font>
											<h3 :title="value.element_name">{{value.element_name}}</h3>
										</a>
									</li>
								</ul>
							</div>
						</div>
						<div class="wxww_boxr sm_mainbox">
							<div class="content_title main_title">
								<i></i>
								<img src="../../images/ww_ico.png" />
								<a href="javascript:void(0)" @click="toMoreList('文物',$CatalogRelicId,0)">更多</a>
							</div>
							<div class="ww_detail_right ww_detail_right2">
								<div class="ww_detail_rightb">
									<a href="javascript:void(0)" :title="value.element_name" @click="toElementDetail(value.id)" v-for="(value,index) in CatalogRelicList" :key="index">{{value.element_name}}</a>
								</div>
							</div>
						</div>
						<div class="wxww_boxr sm_mainbox m_r0">
							<div class="content_title main_title">
								<i></i>
								<img src="../../images/lz_ico.png" />
								<a href="javascript:void(0)" @click="toMoreList('论著',$CatalogBibliographyId,0)">更多</a>
							</div>
							<div class="ww_detail_rightred ww_detail_rightred2">
								<ul>
									<li v-for="(value,index) in CatalogBibliographyList" :key="index">
										<a href="javascript:void(0)" @click="toElementDetail(value.id)">
											<h3 :title="value.element_name">{{value.element_name}}</h3>
										</a>
										<p>{{value.Author}}</p>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--书目遗址人物-->
		<div class="smyzrw_main">
			<div class="main_boxc">
				<div class="sm_mainbox yz_mainbox">
					<div class="content_title main_title">
						<i></i>
						<img src="../../images/yz_ico.png" />
						<a href="javascript:void(0)" @click="toMoreList('遗址',$CatalogSitesId,0)">更多</a>
					</div>
					<div class="sm_contentbox">
						<div class="yz_contentbox134 mainyz_box" v-if="CatalogSitesList.length > 0">
							<h2 :title="CatalogSitesList[0].element_name" @click="toElementDetail(CatalogSitesList[0].id)">{{CatalogSitesList[0].element_name}}</h2>
							<span>{{CatalogSitesList[0].Annotation | GetSubstring(180)}}</span>
						</div>
						<div class="yz_contentbox2">
							<li v-for="(value,index) in CatalogSitesList" :key="index">
								<a href="javascript:void(0)" @click="toElementDetail(value.id)">
									<span :title="value.element_name">{{value.element_name}}</span>
								</a>
							</li>
						</div>
					</div>
				</div>
				<div class="sm_mainbox rwmain_mainbox">
					<div class="content_title main_title">
						<i></i>
						<img src="../../images/rw_ico.png" />
						<a class="color_fff" href="javascript:void(0)" @click="toMoreList('人物',$CatalogFigureId,0)">更多</a>
					</div>
					<div class="sm_contentbox">
						<div class="rw_contentbox2" v-if="CatalogFigureList.length > 0">
							<div class="rw_contentbox213 ">
								<div class="rw_contentbox213l">
									<img :src="$ImgUrl + CatalogFigureList[0].cover_path" :onerror='defaultImage'>
								</div>
								<div class="rw_contentbox213r">
									<a href="javascript:void(0)" @click="toElementDetail(CatalogFigureList[0].id)">
										<h2 :title="CatalogFigureList[0].element_name">{{CatalogFigureList[0].element_name}}</h2>
									</a>
									<!-- <span>周至县</span> -->
								</div>
							</div>
							<div class="rw_contentbox213">
								<span>{{CatalogFigureList[0].Annotation | GetSubstring(38)}}</span>
							</div>
						</div>
						<div class="yz_contentbox2">
							<li v-for="(value,index) in CatalogFigureList.slice(1)" :key="index">
								<a href="javascript:void(0)" @click="toElementDetail(value.id)">
									<span :title="value.element_name">{{value.element_name}}</span>
								</a>
							</li>
						</div>
					</div>
				</div>
				<div class="sm_mainbox qt_mainbox">
					<div class="content_title main_title">
						<i></i>
						<img src="../../images/qt_ico.png" />
						<a href="javascript:void(0)" @click="toMoreList('其他',$CatalogAdditionalId,0)">更多</a>
					</div>
					<div class="sm_contentbox">
						<div class="yz_contentbox2">
							<li v-for="(value,index) in CatalogAdditionalList" :key="index">
								<a href="javascript:void(0)" @click="toElementDetail(value.id)">
									<span :title="value.element_name">{{value.element_name}}</span>
								</a>
							</li>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 知识条目库 -->
		<div class="main_artical">
			<div class="main_artical1">
				<div class="syml_mainbox_title">
					<div class="syml_title">
						<img src="../../images/zstmk_ico.png" />
					</div>
				</div>
				<div class="main_boxc">
					<div class="main_artical1_l">
						<div class="content_title main_title">
							<i></i>
							<img src="../../images/ysjs_ico.png" />
							<a href="javascript:void(0)" @click="toMoreList('艺术鉴赏',$KnowlegeArtId,1)">更多</a>
						</div>
						<div class="main_artical1_lc">
							<div class="main_artical1_lct" v-if="KnowlegeArtList.length > 0">
								<h3 @click="toEntryDetail(KnowlegeArtList[0].id)">{{KnowlegeArtList[0].entry_name}}</h3>
								<p>{{KnowlegeArtList[0].itroduce | GetSubstring(180)}}</p>
							</div>
							<div class="main_artical1_lcb wxww_boxf_list">
								<ul>
									<li v-for="(value,index) in KnowlegeArtList.slice(1)" :key="index">
										<a href="javascript:void(0)" @click="toEntryDetail(value.id)">
											<em></em>
											<!-- <font>{{value.}}</font> -->
											<h3>{{value.entry_name}}</h3>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="main_artical1_r">
						<div class="content_title main_title">
							<i></i>
							<img src="../../images/kgww_ico.png" />
							<a href="javascript:void(0)" @click="toMoreList('考古文物',$KnowlegeRelicId,1)">更多</a>
						</div>
						<div class="main_artical1_rc">
							<ul>
								<li v-for="(value,index) in KnowlegeRelicList" :key="index">
									<h3 @click="toEntryDetail(value.id)">{{value.entry_name}}</h3>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="main_artical2">
				<div class="main_boxc">
					<div class="content_title main_title">
						<i></i>
						<img src="../../images/xstt_ico.png" />
						<a href="javascript:void(0)" @click="toMoreList('学术探讨',$KnowlegeLearnId,1)">更多</a>
					</div>
					<div class="main_artical2con">
						<div class="main_artical2_l" v-if="KnowlegeLearnList.length > 0">
							<div class="main_artical2_l1" @click="toEntryDetail(KnowlegeLearnList[0].id)">
								<img src="../../images/sk_cover.png">
							</div>
							<div class="main_artical2_lc">
								<h3 @click="toEntryDetail(KnowlegeLearnList[0].id)">{{KnowlegeLearnList[0].entry_name}}</h3>
								<p>{{KnowlegeLearnList[0].itroduce | GetSubstring(160)}}</p>
							</div>
						</div>
						<div class="main_artical2_r">
							<ul>
								<li v-for="(value,index) in KnowlegeLearnList.slice(1)" :key="index">
									<h3 @click="toEntryDetail(value.id)">{{value.entry_name}}</h3>
									<span>{{value.Author}}</span>
								</li>
							</ul>
						</div>
					</div>
					<div class="content_title main_title">
						<i></i>
						<img src="../../images/rwzj_ico.png" />
						<a href="javascript:void(0)" @click="toMoreList('人物传记',$KnowlegeFigureId,1)">更多</a>
					</div>
					<div class="main_artical2con main_artical2con2">
						<div class="main_artical2_l" v-if="KnowlegeFigureList.length > 0">
							<div class="main_artical2_lc">
								<h3 @click="toEntryDetail(KnowlegeFigureList[0].id)">{{KnowlegeFigureList[0].entry_name}}</h3>
								<p>{{KnowlegeFigureList[0].itroduce | GetSubstring(280)}}</p>
							</div>
						</div>
						<div class="main_artical2_r">
							<ul>
								<li v-for="(value,index) in KnowlegeFigureList.slice(1)" :key="index">
									<h3 @click="toEntryDetail(value.id)">{{value.entry_name}}</h3>
									<span>{{value.author}}</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--研究成果特色数据库-->
		<div class="yjcgts_main yjbook_main">
			<div class="main_boxc">
				<div class="syml_mainbox_title">
					<div class="syml_title yjcgts_title">
						<img src="../../images/yjcgsjk_ico.png" />
					</div>
				</div>
				<div class="yjcgts_contentbox">
					<div class="content_title main_title">
						<i></i>
						<img src="../../images/ts_ico.png">
						<a href="javascript:;" @click="toSearchAchievtList(0)">更多</a>
					</div>
					<div class="yjbook_mainl" v-if="BookList.length > 0">
						<div class="yjbook_mainl_cover">
							<a href="javascript:void(0)" @click="toBookDetail(BookList[0].id)">
								<img :src="$ImgUrl + BookList[0].cover_path" :onerror="$parent.defaultImg1" />
							</a>
						</div>
						<div class="yjbook_mainl_con">
							<a href="javascript:void(0)" @click="toBookDetail(BookList[0].id)">
								<h3>{{BookList[0].res_name}}</h3>
							</a>
							<font>{{BookList[0].res_author}}</font>
						</div>
					</div>
					<div class="yjbook_mainr">
						<div class="tgtj_ts">
							<ul>
								<li v-for="(value,index) in BookList.slice(1)" :key="index">
									<div class="tgtj_ts_img">
										<a href="javascript:void(0)" @click="toBookDetail(value.id)">
											<img :src="$ImgUrl + value.cover_path" :onerror="$parent.defaultImg1" />
										</a>
									</div>
									<div class="tgtj_ts_content">
										<a href="javascript:void(0)" @click="toBookDetail(value.id)">
											<h3>{{value.res_name}}</h3>
										</a>
										<font>{{value.res_author}}</font>
									</div>
								</li>
							</ul>
						</div>
						<div class="yjbook_mainr1" v-if="BookDataList.length > 0">
							<ul>
								<li v-for="(value,index) in BookDataList" :key="index">
									<i></i>
									<a href="javascript:void(0)" @click="toBookDetail(value.id)">
										<h3>{{value.res_name}}</h3>
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<!--论文-->
			<div class="lw_mainbox">
				<div class="main_boxc">
					<div class="content_title main_title">
						<i></i>
						<img src="../../images/lw_ico.png">
						<a href="javascript:;" @click="toSearchAchievtList(1)">更多</a>
					</div>
					<div class="lw_contentbox lwmain_contentbox">
						<div class="lwmain_contentbox_l" v-if="ArticalList.length > 0">
							<a href="javascript:void(0)" @click="toEntryDetail(ArticalList[0].id)">
								<h3>{{ArticalList[0].entry_name}}</h3>
							</a>
							<p>{{ArticalList[0].itroduce | GetSubstring(200)}}</p>
						</div>
						<div class="wxww_boxf_list lw_boxf_list">
							<ul>
								<li v-for="(value,index) in ArticalList.slice(1)" :key="index">
									<a href="javascript:void(0)" @click="toEntryDetail(value.id)">
										<em></em>
										<!-- <font>S.0159</font> -->
										<h3>{{value.entry_name}}</h3>
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div class="qk_contentbox">
						<div class="qk_contentbox1">
							<div class="content_title main_title">
								<i></i>
								<img src="../../images/qk_ico.png">
							</div>
							<div class="qk_contentbox1_list mainqk_contentbox1_list">
								<li v-for="(value,index) in journalList" :key="index">
									<a href="javascript:void(0)" @click="toJournalDetail(value.ID)">
										<div class="qk_image">
											<div class="qk_imagebg">
												<img :src="$ImgUrl + value.CoverPath" :onerror="$parent.defaultImg1" />
											</div>
										</div>
										<h3>{{value.JournalName}}</h3>
									</a>
								</li>
							</div>
						</div>
						<div class="qk_contentbox2">
							<div class="content_title main_title">
								<i></i>
								<img src="../../images/qt_ico.png">
								<a href="javascript:;" @click="toSearchAchievtList(2)">更多</a>
							</div>
							<div class="qt_contentbox_list">
								<li v-for="(value,index) in OtherArticalList" :key="index">
									<a href="javascript:void(0)" @click="toEntryDetail(value.id)">
										<h3>{{value.entry_name}}</h3>
									</a>
									<p>{{value.itroduce | GetSubstring(28)}}</p>
								</li>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


		<!--遗珍数据库-->
		<div class="yzsjk_mainbox1">
			<div class="main_boxc">
				<div class="syml_mainbox_title yzsjk_mainbox_title">
					<div class="syml_title">
						<img src="../../images/yzsjk_ico.png" />
					</div>
				</div>
			</div>
		</div>
		<div class="yzsjk_mainbox2">
			<div class="main_boxc">
				<div class="yzsjk_titlebox">
					<ul id="navBar">
						<li :class="curLegacyClassIndex == 0?'class_cur':''">
							<a href="javascript:;" @click="ChangeClass(0)"><span>彩塑</span></a>
						</li>
						<li :class="curLegacyClassIndex == 1?'class_cur':''">
							<a href="javascript:;" @click="ChangeClass(1)"><span>壁画</span></a>
						</li>
						<li :class="curLegacyClassIndex == 2?'class_cur':''">
							<a href="javascript:;" @click="ChangeClass(2)"><span>出土文物</span></a>
						</li>
						<li :class="curLegacyClassIndex == 3?'class_cur':''">
							<a href="javascript:;" @click="ChangeClass(3)"><span>石窟</span></a>
						</li>
						<li :class="curLegacyClassIndex == 4?'class_cur':''">
							<a href="javascript:;" @click="ChangeClass(4)"><span>遗址</span></a>
						</li>
						<li :class="curLegacyClassIndex == 5?'class_cur':''">
							<a href="javascript:;" @click="ChangeClass(5)"><span>文献</span></a>
						</li>
						<li :class="curLegacyClassIndex == 6?'class_cur':''">
							<a href="javascript:;" @click="ChangeClass(6)"><span>人物</span></a>
						</li>
						<li :class="curLegacyClassIndex == 7?'class_cur':''">
							<a href="javascript:;" @click="ChangeClass(7)"><span>艺术品</span></a>
						</li>
						<li :class="curLegacyClassIndex == 8?'class_cur':''">
							<a href="javascript:;" @click="ChangeClass(8)"><span>出版物</span></a>
						</li>
						<li :class="curLegacyClassIndex == 9?'class_cur':''">
							<a href="javascript:;" @click="ChangeClass(9)"><span>其他</span></a>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="yzsjk_mainbox3 yzsjkindex_mainbox3">
			<div class="main_boxc">
				<div class="yzsjkindex_mainbox3l" v-if="LegacyList.length > 0">
					<a href="javascript:;" @click="toImageDetail(LegacyList[0].id)">
						<img :src="$ImgUrl + LegacyList[0].image_file_path" :onerror="$parent.defaultImg1" />
						<h3>{{LegacyList[0].image_name}}</h3>
					</a>
				</div>
				<div class="gwxsjk_contentbox">
					<div class="syml_list yzsjk_boxr_list">
						<ul>
							<li v-for="(value,index) in LegacyList.slice(1)" :key="index">
								<a href="javascript:;" @click="toImageDetail(value.id)">
									<img :src="$ImgUrl + value.image_file_path" :onerror="$parent.defaultImg1">
									<h3>{{value.image_name}}</h3>
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div class="button_box">
					<a href="javascript:;" @click="toMoreList(curLegacyClassName,curLegacyClassId,3)">查看更多<img src="../../images/gt.png" />
					</a>
				</div>
			</div>
		</div>
		<!--古文献数据库-->
		<div class="gwxsjk_mainbox gwxsjkindex_mainbox">
			<div class="main_boxc">
				<div class="syml_mainbox_title">
					<div class="syml_title">
						<img src="../../images/gwxsjk_ico.png" />
					</div>
				</div>
				<div class="gwxsjk_contentbox">
					<div class="gwxsjk_boxr_list">
						<ul>
							<li v-for="(value,index) in AnLiteratureList" :key="index" @click="toReadingPdf(value)">
								<a href="javascript:void(0)">
									<img :src="$ImgUrl+value.CoverPath" :onerror="$parent.defaultImg1">
								</a>
								<h3 :title="value.Name">{{value.Name}}</h3>
							</li>
						</ul>
					</div>
					<div class="button_box">
						<a href="javascript:void(0)" @click="toAnLiteratureList()">查看更多<img src="../../images/gt.png" /> </a>
					</div>
				</div>
			</div>
		</div>
		<!--科研辅助平台、友情链接-->
		<div class="zy_mainbox kycg_mainbox">
			<div class="main_boxc">
				<div class="sm_mainbox ky_mainbox">
					<div class="content_title main_title">
						<i></i>
						<img src="../../images/kyfzpt_ico.png">
					</div>
					<div class="sm_contentbox kyfzpt_contentbox">
						<div class="zxtg_btn">
							<a href="javascript:void(0)" @click="toManuscriptEdit()">
								<img src="../../images/zxtg_bg.png" />
							</a>
						</div>
						<div class="kyfzpt_contentboxli">
							<li>
								<a href="http://dh.ersjk.com/" target="_blank">
									<img src="../../images/dhwxk_bg.png" />
									<span>敦煌文献库</span>
								</a>
							</li>
							<li>
								<a href="http://dh.ersjk.com/" target="_blank">
									<img src="../../images/zgfzk_bg.png" />
									<span>中国方志库</span>
								</a>
							</li>
							<li>
								<a href="http://dh.ersjk.com/" target="_blank">
									<img src="../../images/zgpdk_bg.png" />
									<span>中国谱牒库</span>
								</a>
							</li>
							<li>
								<a href="http://dh.ersjk.com/" target="_blank">
									<img src="../../images/zgjsk_bg.png" />
									<span>中国金石库</span>
								</a>
							</li>
						</div>
					</div>
				</div>
				<div class="yqlj_mainbox">
					<h4>友情链接：</h4>
					<div class="yqlj_mainboxli">
						<ul>
							<li>
								<a href="http://www.ncha.gov.cn/" target="_blank">
									<span>国家文物局</span>
								</a>
							</li>
							<li>
								<a href="http://www.dha.ac.cn/" target="_blank">
									<span>敦煌研究院</span>
								</a>
							</li>
							<li>
								<a href="http://wwj.gansu.gov.cn/" target="_blank">
									<span>甘肃省文物局</span>
								</a>
							</li>
							<li>
								<a href="http://www.gansumuseum.com/" target="_blank">
									<span>甘肃省博物馆</span>
								</a>
							</li>
							<li>
								<a href="http://www.duzhepmc.com/" target="_blank">
									<span>读者出版集团</span>
								</a>
							</li>
							<li>
								<a href="https://www.e-dunhuang.com/index.htm" target="_blank">
									<span>数字敦煌</span>
								</a>
							</li>
							<li>
								<a href="http://www.dhyj.net.cn/" target="_blank">
									<span>敦煌研究</span>
								</a>
							</li>
							<li>
								<a href="http://www.siluyou.org/" target="_blank">
									<span>丝绸之路</span>
								</a>
							</li>
							<li>
								<a href="http://www.lmsk.cn/index.asp" target="_blank">
									<span>龙门石窟</span>
								</a>
							</li>
							<li>
								<a href="http://www.mjssk.cn" target="_blank">
									<span>麦积山石窟</span>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<Footer></Footer>
		<LoginPop :showLogin='showLogin'></LoginPop>
		<div class="video_player" v-show="showPlayer">
			<div class="video_player_c" :style="curClassType == 5?'width: 1200px':'width: 400px;'">
				<div class="dltc_box1_maint">
					<h4>播放</h4>
					<button class="Pop_btn" style="top: 5px;" @click="hidePlayer">
						<img src="../../images/ts_close.png" />
					</button>
				</div>
				<div v-if="curClassType == 4" class="index_audio">
					<div class="index_audioc">
						<div class="index_audioc_l">
							<img v-if="!audioInfo.CoverPath" src="../../images/audio_cover.png"/>
							<img v-if="audioInfo.CoverPath" :src="$ImgUrl + audioInfo.CoverPath"/>
						</div>
						<div class="index_audioc_r">
							<h3 :title="audioInfo.ResName">{{audioInfo.ResName}}</h3>
							<span v-if="audioInfo.Time">时长：{{audioInfo.Time}}</span>
						</div>
					</div>
					<audio class="media" ref="player" controlsList="nodownload" :src="$ImgUrl + mediaFilePath" controls="controls"></audio>
				</div>
				<div v-if="curClassType == 5">
					<div class="play_layerc1" v-if="mediaFilePath && mediaFilePath.indexOf('<iframe') == -1">
						<video class="media" ref="videoPlayer" autoplay="autoplay" :src="$ImgUrl + mediaFilePath" controls="controls" controlsList="nodownload"></video>
					</div>
					<div class="play_layerc1" v-if="mediaFilePath && mediaFilePath.indexOf('<iframe') != -1" v-html="mediaFilePath"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script src="./Index.js">
</script>

<style scoped="scoped">
	.audio_cover {
		border: 2px solid #eaddd4;
		width: 100%;
		height: 126px;
		box-sizing: border-box;
		text-align: center;
		line-height: 122px;
	}

	.audio_cover img {
		width: auto !important;
		height: auto !important;
		border: none;
		vertical-align: middle;
		max-width: 100%;
		max-height: 100%;
	}

	.video_player {
		position: fixed;
		z-index: 1000000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		display: flex;
		justify-content: space-around;
		align-items: center;
	}

	.video_player_c {
		width: 400px;
		min-height: 160px;
		background: #fff;
		box-sizing: border-box;
		overflow: hidden;
	}

	.video_player_c .dltc_box1_maint {
		width: 100%;
	}

	.video_player_c audio {
		margin: 50px 40px 0;
		width: calc(100% - 80px);
		min-height: 30px;
		display: block !important;
		outline: none;
	}

	.play_layerc1 {
		float: left;
		width: 100%;
		padding: 18px;
		box-sizing: border-box;
	}

	.play_layerc1 video {
		float: left;
		width: 100%;
		height: 500px;
		outline: none;
	}

	.play_layerc1 iframe {
		width: 100%;
		height: 500px;
	}
</style>
